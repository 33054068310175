<template>
  <div class="overall">
    <!-- 上半部分 -->
    <el-card class="chart-card">
      <!-- 上半部分右边内容 -->
      <div class="plane">
        <!-- 右边头部 -->
        <div class="ritherd">
          <el-col :span="8">
            <div class="impose">
              <el-card class="a" shadow="hover">
                <div class="amount">
                  <div class="union">
                    <el-icon size="35px" color="#ffffff"><WalletFilled /></el-icon>
                  </div>
                  <div class="magnitude">
                    <div class="count">{{departmentCount}}</div>
                    <div class="crew">总发行积分</div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="impose1">
              <el-card shadow="hover">
                <div class="amount">
                  <div class="union1">
                    <!-- <el-icon size="35px" color="#ffffff"
                      ><Van
                    /></el-icon> -->
                    <img src="../../assets/fenpei.png" alt="">
                  </div>
                  <div class="magnitude">
                    <div class="count1">{{userCount}}</div>
                    <div class="crew">可分配积分</div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </div>
        <!-- 积分派发 -->
        <div class="plane">
          <!-- <div>
            积分派发：
          </div> -->
          <div class="distribute">
            <div class="payout">
              积分发行：
            </div>
             <el-button type="primary" @click="distribution" size="default" :loading='whirl'>发行积分</el-button>
          </div>
        </div>
         <div class="statistics">
            统计：{{zong}}条
          </div>
        <!-- 表格内容 -->
        <!-- 表格 -->
        <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
      <!-- 发行积分值  -->
        <el-table-column 
          align="center" 
          label="发行积分值 " 
          prop="integralValue"
          :show-overflow-tooltip='true'
        />
        <!-- 发行管理员 -->
        <el-table-column
          label="发行管理员"
          prop="username"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 发行时间 -->
        <el-table-column
          label="发行时间"
          prop="createTime"
          align="center"
          :formatter="reach"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          label="备注"
          prop="reason"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]" 
        :page-size="pagesize"
        :total="totality"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      </div>
    </el-card>
  </div>
</template>
<script>
import { WalletFilled, Van, ShoppingCartFull } from "@element-plus/icons-vue";
import { useRouter,useRoute } from "vue-router";
import { ref,onMounted,onActivated } from 'vue';
// import { ElMessage } from "element-plus";
import { JFissue,Listissue } from "../../../utils/api";
import qs from 'qs';
import moments from 'moment'
export default {
  name:"JFpublish",
  computed:{
  },
  setup() {
    const route = useRoute();
    // 分页处理
    // 数据增加
    let branches=ref(6)
    // 数据总数
    let totality =ref(0)
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      list()
      // if(input.value!==''|| ability.value!==''||values.value!==''||values1.value!==''){
      //   search()
      // }else{
      //   list()
      // }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      list()
      // if(input.value!==''|| ability.value!==''||values.value!==''||values1.value!==''){
      //   search()
      // }else{
      //   list()
      // }
    }
    //  发行跳转
    let distribution=()=>{
      router.push('/JFcirculation')
    }
    // 数据总共条数
    let zong =ref(0)
    let reach=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format().substring(0,10)
      }
    }
    // 列表数据循环
    const filterTableData = ref([])
    // 表格数据接口
    let list =()=>{
      let data={
        limit:branches.value,
        page:currentPage.value
      }
      Listissue(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          zong.value=res.data.data.issuePage.totalCount
          totality.value=res.data.data.issuePage.totalCount
          filterTableData.value=res.data.data.issuePage.list
          // console.log(filterTableData.value)
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }


    // 加载中
    let texts = ref('加载中')



    // 搜索框变化值


    // 总派发积分
    const departmentCount =ref(0)
    // 在途积分
    const userCount=ref(0)
    // 可派发积分
    const kepaifa=ref(0)
    // 已换购积分
    const integralCount=ref(0)


    // 时间选择
    const whirl=ref(false)
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      if(translate.value==1){
        texts.value='加载中'
        list()
        publish()
      }
      // console.log(translate.value)
    })


    onMounted(()=>{
      list()
      publish()
    })
    // 获取积分发行统计
    let publish=()=>{
      JFissue().then((res)=>{
        // console.log(res)
        if(res.data.code==0){
          // 总发行积分
          departmentCount.value=res.data.data.integralIssue.issueIntegral
          // 可派发积分
          userCount.value=res.data.data.integralIssue.distributionIntegral
        }
      })
    }
    // 待办内容点击事件
    const router = useRouter()
    // 待办内容
    return {
      totality,
      handleCurrentChange,
      handleSizeChange,
      pagesize,
      currentPage,
      distribution,
      zong,
      // 下载模板
      reach,
      filterTableData,
      // 可派发积分
      kepaifa,
      // 已换购积分
      integralCount,
      // 在途积分
      userCount,
      // 派发总积分
      departmentCount,
      // 时间选择
      whirl,
      texts
    };
  },
  components: {
    WalletFilled,
    Van,
    ShoppingCartFull,
  },
};
</script>
<style lang='scss' scoped>
	.quan {
		width: 35px;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.upload-demo {
		margin-right: 10px;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: block !important;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	// 派发积分模块
	.payout {
		font-size: 17px;
		font-weight: bold;
		margin-right: 10px;
	}

	.distribute {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.hunt {
		width: 27%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 170px);
		overflow: auto;
		padding: 10px;
	}

	.el-avatar {
		--el-avatar-bg-color: #F5F5F5;
	}

	.statistics {
		font-size: 14px;
		color: #909399;
		margin-bottom: 15px;
	}

	// 右半部分下边内容
	.el-table--enable-row-transition .el-table__body td.el-table__cell {
		cursor: pointer;
	}

	.el-checkbox__label {
		padding-left: 25px;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.el-table__footer {
		width: 96% !important;
		margin: auto;
		// margin-top: 10px;
		// margin-bottom: 20px;
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.box-card {
		width: 480px;
	}

	// 右半部分上边内容
	.impose2 {
		margin-left: 20px;
	}

	.impose1 {
		margin-left: 20px;
	}

	.crew {
		font-size: 14px;
		color: #999;
	}

	.count1 {
		font-size: 30px;
		font-weight: bold;
		color: rgb(100, 213, 114);
	}

	.count2 {
		font-size: 30px;
		font-weight: bold;
		color: rgb(242, 94, 67);
	}

	.count {
		font-size: 30px;
		font-weight: bold;
		color: rgb(45, 140, 240);
	}

	.magnitude {
		width: 71%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.el-card {
		width: 99%;
	}

	.amount {
		display: flex;
		flex-direction: row;
	}

	.ritherd {
		width: 100%;
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		// justify-content: space-evenly;
	}

	.union1 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(100, 213, 114);
	}

	.union2 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(242, 94, 67);
	}

	.union {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(45, 140, 240);
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.plane {
		width: 98%;
		// margin-left: 20px;
	}

	// 左半部分上边内容
	@media screen and (max-width: 1200px) {
		.tiem {
			display: none !important;
		}
	}

	.quantum {
		margin-right: 70px;
	}

	.tiem {
		font-size: 14px;
		color: #999;
		line-height: 25px;
		display: flex;
		align-items: center;
	}

	.inventory {
		width: 40%;
		overflow: hidden;
		border-radius: 4px;
	}

	.username {
		margin-left: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.name {
			font-size: 30px;
			color: #222;
		}

		.super {
			font-size: 14px;
			color: #999;
		}
	}

	.stration {
		padding-bottom: 20px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #ccc;
	}

	.message {
		background-color: #ffffff;
		padding: 20px;
	}

	// 上半部分内容
	// .upside {
	//   display: flex;
	// }
	// 整体
	.overall {
		/* padding-left: 10px;
  padding-top: 10px;
  // margin-top: 30px;
  height: 94%; */
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
</style>